import { useEffect } from "react";
import { Alert, ButtonRow, Button, Text } from "@myob/myob-widgets";
import { HELP_ME_TICKET_URL } from "../helpMeTicketUrl";

const DEFAULT_ERROR =
  "We've encountered an error. Please check back again later. If the issue persists, please submit a Help Me ticket";

function GenericError({ errorMessages, hideDefaultHelpMeTicketText = false }) {
  const STORAGE = window.localStorage;
  useEffect(() => {
    const clearAllDataPersist = (storageObj = window.localStorage) => {
      const keysToRemove = [...Object.keys(storageObj).filter((key) => String(key).startsWith("employment-forms."))];
      keysToRemove.forEach((key) => {
        storageObj.removeItem(key);
      });
    };
    clearAllDataPersist(STORAGE);
  }, [STORAGE]);

  const messages = errorMessages?.map((errorMessage) => (
    <Text key={errorMessage} dangerouslySetInnerHTML={{ __html: errorMessage }}></Text>
  ));

  return (
    <>
      <Alert type="danger">
        {messages || DEFAULT_ERROR}
        {!hideDefaultHelpMeTicketText && (
          <p key="helpmetickettext">
            You can submit a HelpMe Ticket{" "}
            <a href={HELP_ME_TICKET_URL} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </p>
        )}
      </Alert>
      <ButtonRow>
        <Button onClick={() => (window.location.href = "/")}>Back</Button>
      </ButtonRow>
    </>
  );
}

export default GenericError;
