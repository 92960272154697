import { createContext, useState } from "react";

const StatusContext = createContext([{ disabled: false }, () => {}]);

const StatusProvider = (props) => {
  const [status, setStatus] = useState({ disabled: props?.disabled || false });
  return <StatusContext.Provider value={[status, setStatus]}>{props.children}</StatusContext.Provider>;
};

export { StatusContext, StatusProvider };
