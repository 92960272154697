import { PageState, Spinner, Card } from "@myob/myob-widgets";
import { cardMarginStyle } from "./Card.css";

const CardWithLoadingStatus = ({ title = "Loading" }) => {
  return (
    <div className={cardMarginStyle}>
      <Card>
        <PageState title={title} image={<Spinner size="medium" />}></PageState>
      </Card>
    </div>
  );
};

export default CardWithLoadingStatus;
