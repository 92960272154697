import { StatusCodes, getReasonPhrase } from "http-status-codes";
import { json } from "react-router-dom";
import { isAfter, isBefore } from "date-fns";
import {
  checkPermission,
  getManagedUsers,
  getUsersClass,
  getDepartureFormByIdResponse,
  checkPermissionForOnboarding,
  getOnboardingFormById,
  getOnboardingFormByLeverOpportunityId,
  getRoleUpdateFormByLeverOpportunityId,
  getDepartedContractorUsersClass,
  checkPermissionForRoleUpdate,
  getRoleUpdateFormById,
  checkPoePermission,
  checkPermissionForProofOfEmploymentUpdate,
  getProofOfEmploymentFormById,
  checkIfFormIsOpen,
  getValidThirdPartyCompanies,
} from "../../apis/kilnBackendApis";
import { formTypeToApiEndpointMapping, formTypeToFormNameMapping, isNilOrEmpty, isNotNilEmpty } from "../../utils";
import UserFactory from "../../factories/UserFactory";

function badRequest(msg) {
  return new Response(msg ?? getReasonPhrase(StatusCodes.BAD_REQUEST), { status: StatusCodes.BAD_REQUEST });
}

function notFound() {
  return new Response(getReasonPhrase(StatusCodes.NOT_FOUND), { status: StatusCodes.NOT_FOUND });
}

function unauthorised() {
  return new Response(getReasonPhrase(StatusCodes.UNAUTHORIZED), { status: StatusCodes.UNAUTHORIZED });
}

const FormTypePermissionCheckLoader = async ({ request: { url } }) => {
  const splitPathname = new URL(url)?.pathname?.split("/");
  const formTypeFromPathname = splitPathname.at(splitPathname.indexOf("form") + 1);
  const formTypeForApi = formTypeToApiEndpointMapping[formTypeFromPathname];

  if (!formTypeForApi) {
    throw new Error("Bad Form Type");
  }
  const resp = await checkPermission(formTypeForApi);
  if (resp.status === StatusCodes.NOT_FOUND) {
    throw notFound();
  }
  if (resp.status === StatusCodes.UNAUTHORIZED) {
    throw unauthorised();
  }
  if (resp.status === StatusCodes.FORBIDDEN) {
    throw json(
      {
        formType: formTypeToFormNameMapping[formTypeFromPathname] ?? "Employment",
      },
      { status: StatusCodes.FORBIDDEN },
    );
  }
  if (resp.status === StatusCodes.OK) {
    return new Response();
  }
  throw new Error();
};

export const GetAllEmployeesLoader = async ({ request }) => {
  await FormTypePermissionCheckLoader({ request });
  const employees = await getUsersClass();
  if (!employees) {
    throw new Error();
  }

  return { employees };
};

export const GetDepartureFormByIdLoader = async ({ params: { id } }) => {
  const formId = parseInt(id, 10);
  if (Number.isNaN(formId)) {
    throw badRequest("Incorrect format");
  }
  const resp = await getDepartureFormByIdResponse(formId);
  if (resp.status === StatusCodes.NOT_FOUND) {
    throw notFound();
  }
  if (resp.status === StatusCodes.FORBIDDEN) {
    throw json({ formType: "Departure" }, { status: StatusCodes.FORBIDDEN });
  }
  if (resp.status !== StatusCodes.OK) {
    throw new Error(resp?.data?.message);
  }

  return json(resp.data);
};

export const GetOnboardingFormByIdLoader = async ({ params }) => {
  const formId = parseInt(params?.id, 10);
  if (Number.isNaN(formId)) {
    throw badRequest("Incorrect format");
  }

  const permissionResp = await checkPermissionForOnboarding(formId);
  if (permissionResp.status === StatusCodes.FORBIDDEN) {
    throw json({ formType: "Onboarding" }, { status: StatusCodes.FORBIDDEN });
  }
  if (permissionResp.status === StatusCodes.NOT_FOUND) {
    throw notFound();
  }

  const resp = await getOnboardingFormById(formId);
  if (resp.status === StatusCodes.NOT_FOUND) {
    throw notFound();
  }
  if (resp.status !== StatusCodes.OK) {
    throw new Error(resp?.data?.message);
  }

  return json(resp.data);
};

export const GetOnboardingFormByOpportunityIdIdLoader = async (requestUrl) => {
  const url = new URL(requestUrl);
  const opportunityId = url.searchParams.get("opportunityId");

  if (!opportunityId) {
    return { opportunityId: null, data: null };
  }

  const response = await getOnboardingFormByLeverOpportunityId(opportunityId);
  if (response.status !== StatusCodes.OK) {
    throw new Error(response?.data?.message);
  }

  return { opportunityId: opportunityId, data: response.data };
};

export const GetAllEnabledEmployeesLoader = async ({ request }) => {
  await FormTypePermissionCheckLoader({ request });
  const employeesClass = await getUsersClass();
  if (!employeesClass) {
    throw new Error();
  }

  const employees = employeesClass.filter((user) => user.isADAccountEnabled);
  return { employees };
};

export const GetRoleUpdateFormByOpportunityIdIdLoader = async (requestUrl) => {
  const url = new URL(requestUrl);
  const opportunityId = url.searchParams.get("opportunityId");

  if (!opportunityId) {
    return { opportunityId: null, data: null };
  }

  const response = await getRoleUpdateFormByLeverOpportunityId(opportunityId);
  if (response.status !== StatusCodes.OK) {
    throw new Error(response?.data?.message);
  }

  return { opportunityId: opportunityId, data: response.data };
};

export const GetRoleUpdateEmployeesLoader = async ({ request }) => {
  await FormTypePermissionCheckLoader({ request });
  const employeesClass = await getUsersClass();
  if (!Array.isArray(employeesClass)) {
    throw new Error();
  }

  const employees = employeesClass.filter((user) => user.isADAccountEnabled);

  const inactiveAndDepartedUsers = await getDepartedContractorUsersClass();

  if (!Array.isArray(inactiveAndDepartedUsers)) {
    throw new Error();
  }
  if (isNotNilEmpty(employeesClass) && isNotNilEmpty(employees) && employeesClass.length !== employees.length) {
    inactiveAndDepartedUsers.push(...employeesClass.filter((user) => !employees.includes(user)));
  }

  return { employees, inactiveAndDepartedUsers };
};

export const GetRoleUpdateFormByIdLoader = async ({ params }) => {
  const formId = parseInt(params?.id, 10);
  if (Number.isNaN(formId)) {
    throw badRequest("Incorrect format");
  }

  const permissionResp = await checkPermissionForRoleUpdate(formId);
  if (permissionResp.status === StatusCodes.FORBIDDEN) {
    throw json({ formType: "Role-Update/Transfer" }, { status: StatusCodes.FORBIDDEN });
  }
  if (permissionResp.status === StatusCodes.NOT_FOUND) {
    throw notFound();
  }
  if (permissionResp.status !== StatusCodes.OK) {
    throw new Error();
  }

  const resp = await getRoleUpdateFormById(formId);
  if (resp.status === StatusCodes.NOT_FOUND) {
    throw notFound();
  }
  if (resp.status !== StatusCodes.OK) {
    throw new Error(resp?.data?.message);
  }

  return json(resp.data);
};

export const GetTalentReviewActiveEmployeesLoader = async ({ request }) => {
  await FormTypePermissionCheckLoader({ request });
  const managedUsers = await getManagedUsers();
  if (!Array.isArray(managedUsers)) {
    throw new Error();
  }
  const today = new Date();
  const employees = managedUsers.filter(
    (user) =>
      user?.isADAccountEnabled &&
      user?.employmentType?.toUpperCase() !== "CAS" &&
      isBefore(new Date(user?.startDate ?? "1970-01-01"), today) &&
      !isAfter(today, new Date(user?.plannedTerminationDate || today)),
  );

  if (isNilOrEmpty(employees)) {
    throw json({ formType: "Talent Review" }, { status: StatusCodes.IM_A_TEAPOT });
  }

  return { employees };
};

export const GetProofOfEmploymentEmployeesLoader = async () => {
  const res = await checkPoePermission();
  if (res.status === StatusCodes.FORBIDDEN) {
    throw json({ formType: "Proof of Employment" }, { status: res.status });
  }

  const hasFullAccess = res?.data?.hasFullAccess;
  const currentUser = res?.data?.user ? UserFactory.create(res?.data?.user) : undefined;

  let employeesClass;
  let employees = [];
  const wholeEmployeeList = await getUsersClass();
  //Authorised
  if (hasFullAccess) {
    //Full access - dropdown will display all users
    employeesClass = wholeEmployeeList;
  } else {
    //Normal access - dropdown will display user (if not COA) and all direct reports (if any)
    employeesClass = (await getManagedUsers()).map((u) => {
      return {
        ...u,
        manager: currentUser,
      };
    });
  }

  if (!Array.isArray(employeesClass)) {
    throw new Error();
  }

  //Remove inactive users and remove all COAs
  if (employeesClass.length > 0) {
    employees = employeesClass.filter((user) => user?.isADAccountEnabled && user?.employmentType !== "COA");
  }
  const isCurrentUserValidEmployee = wholeEmployeeList.some((employee) => employee.id === currentUser.id);
  if (isCurrentUserValidEmployee) {
    employees.push(currentUser);
  }

  return { employees, currentUser, isCurrentUserValidEmployee };
};

export const GetProofOfEmploymentByIdLoader = async ({ params }) => {
  const formId = parseInt(params?.id, 10);
  if (Number.isNaN(formId)) {
    throw badRequest("Incorrect format");
  }

  const permissionResp = await checkPermissionForProofOfEmploymentUpdate(formId);
  if (permissionResp.status === StatusCodes.NOT_FOUND) {
    throw notFound();
  }
  if (permissionResp.status === StatusCodes.FORBIDDEN) {
    throw json({ formType: "Proof of Employment" }, { status: StatusCodes.FORBIDDEN });
  }

  const resp = await getProofOfEmploymentFormById(formId);

  if (resp.status !== StatusCodes.OK) {
    throw new Error(resp?.data?.message);
  }

  return json(resp.data);
};

export const GetEoYEmployeesLoader = async ({ request }) => {
  await FormTypePermissionCheckLoader({ request });
  const resp = await checkIfFormIsOpen();
  if (resp.status !== StatusCodes.OK) {
    throw json({ formType: "End of Year - Form Closed" }, { status: StatusCodes.IM_A_TEAPOT });
  }

  const employeesList = await getManagedUsers();

  if (isNilOrEmpty(employeesList)) {
    throw json({ formType: "End of Year - No Direct Reports" }, { status: StatusCodes.IM_A_TEAPOT });
  }

  const today = new Date();
  const employees = employeesList.filter(
    (user) =>
      user?.isADAccountEnabled &&
      user?.employmentType?.toUpperCase() !== "CAS" &&
      isBefore(new Date(user?.startDate ?? "1970-01-01"), today) &&
      !isAfter(today, new Date(user?.plannedTerminationDate ?? today)),
  );

  return { employees };
};

export const GetThirdPartyLoader = async ({ request }) => {
  await FormTypePermissionCheckLoader({ request });
  const [companyList, employeesClass] = await Promise.all([getValidThirdPartyCompanies(), getUsersClass()]);
  if (!employeesClass || !companyList) {
    throw new Error();
  }
  const employees = employeesClass.filter((user) => user.isADAccountEnabled);

  const companies = companyList.data.map((company) => {
    const updatedCompany = { ...company };
    updatedCompany.taxNumber = company.abn || company.nzbn;
    return updatedCompany;
  });
  return { companies, employees };
};
