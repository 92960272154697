import { Container, ThemeProvider, ToastProvider } from "@myob/myob-widgets";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../../apis/kilnBackendApis";
import Header from "./Header";
import CardWithLoadingStatus from "./CardWithLoadingStatus";
import UserContext from "../../auth/UserContext";
import { redirectRequest } from "../../auth/msalConfig";
import { CustomNavigationClient } from "../../auth/CustomNavigationClient";
import { mainContainerStyle } from "../../styles/custom.css.ts";

const PageLayout = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userLoggedIn, setUserLoggedIn] = useState(null);
  const [currentTheme, setCurrentTheme] = useState(true);
  const navigation = useNavigation();
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  instance.setNavigationClient(navigationClient);

  useEffect(() => {
    const getAndSaveCurrentUser = async () => {
      setUserLoggedIn((await getCurrentUser()).data);
    };
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenRedirect({
          ...redirectRequest,
          account: instance.getActiveAccount(),
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    }
    if (!userLoggedIn && isAuthenticated) {
      // eslint-disable-next-line no-console
      getAndSaveCurrentUser().catch((err) => console.error(err));
    }
  }, [inProgress, instance, isAuthenticated, userLoggedIn]);

  return (
    <UserContext.Provider value={userLoggedIn}>
      <ThemeProvider theme={currentTheme ? "modern" : "classic"}>
        <Header theme={currentTheme} setTheme={setCurrentTheme} />
        <ToastProvider>
          <Container className={mainContainerStyle}>
            {navigation.state !== "idle" ? <CardWithLoadingStatus /> : <Outlet />}
          </Container>
        </ToastProvider>
      </ThemeProvider>
    </UserContext.Provider>
  );
};

export default PageLayout;
