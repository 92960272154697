import { BrowserAuthError, InteractionRequiredAuthError, LogLevel, PublicClientApplication } from "@azure/msal-browser";

/** @type {import("@azure/msal-browser").Configuration} */
const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_TENANT_ID}`,
    clientId: import.meta.env.VITE_CLIENT_ID,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage", //allows session to be shared across tabs
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    allowRedirectInIframe: true,
    loggerOptions: {
      logLevel: import.meta.env.VITE_ENV === "default" ? LogLevel.Warning : LogLevel.Error,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);
            return;
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * You must call and await the initialize function before attempting to call any other MSAL API.
 *
 * @remarks
 * [`uninitialized_public_client_application`](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/ceccf0b96f90a6ed747e3ed6eac3e0ee2df81939/lib/msal-browser/docs/errors.md?plain=1#L366-L405)
 *
 *
 * @example
 * ```javascript
 * const msalInstance = new PublicClientApplication({
 *     auth: {
 *         clientId: "your-client-id",
 *     },
 *     system: {
 *         allowNativeBroker: true,
 *     },
 * });
 *
 * await msalInstance.initialize();
 * await msalInstance.handleRedirectPromise(); // This will no longer throw this error since initialize completed before this was invoked
 * msalInstance.acquireTokenSilent(); // This will also no longer throw this error
 ```
 */
await msalInstance.initialize();
await msalInstance.handleRedirectPromise();

export const redirectRequest = {
  scopes: [import.meta.env.VITE_CLIENT_ID + "/.default"],
  redirectUri: "/",
};

export const getToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts && accounts.length === 1) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...redirectRequest,
        account: accounts[0],
      });
      const { accessToken } = response;

      return accessToken;
    } catch (e) {
      if (e instanceof BrowserAuthError) {
        const response = await msalInstance.acquireTokenRedirect({
          ...redirectRequest,
          account: accounts[0],
        });
        const { accessToken } = response;

        return accessToken;
      }
      if (e instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect({
          ...redirectRequest,
          account: msalInstance.getActiveAccount(),
        });
      }
    }
  } else {
    msalInstance.logoutRedirect().catch((err) => {
      // eslint-disable-next-line no-console
      console.error("Error retrieving token, no active account has been set", err);
    });
  }
};
