import { Alert, ButtonRow, Button } from "@myob/myob-widgets";
import { path, isEmpty } from "ramda";
import { useEffect } from "react";
import { useNavigate, useLocation, useRouteError } from "react-router-dom";
import { HELP_ME_TICKET_URL } from "./helpMeTicketUrl";

const DEFAULT_ERROR =
  "We've encountered an error. Please check back again later. If the issue persists, please submit a Help Me ticket ";

const ErrorPage = () => {
  const location = useLocation();
  const history = useNavigate();
  const error = useRouteError();
  const buttonConfig = path(["state", "button"], location) || { text: "Back" };
  const { clickUrl, text } = buttonConfig;
  const onClickButton = clickUrl ? () => history(clickUrl, { replace: true }) : () => (window.location.href = "/");
  const hideHelpMeTicketText = path(["state", "hideHelpMeTicketText"], location);
  const messages = path(["state", "messages"], location) || [];

  const STORAGE = window.localStorage;
  const helpMeTicketLink = (
    <a href={HELP_ME_TICKET_URL} target="_blank" rel="noopener noreferrer">
      here
    </a>
  );

  const messagesDisplay = messages?.map((message) => <p key={message}>{message}</p>);
  if (isEmpty(messages)) {
    messagesDisplay.push(
      <p key="defaultmessage">
        {error?.data?.message || DEFAULT_ERROR} {helpMeTicketLink}.
      </p>,
    );
  } else if (!hideHelpMeTicketText) {
    messagesDisplay.push(<p key="helpmetickettext">Please submit a Help Me Ticket {helpMeTicketLink}.</p>);
  }

  useEffect(() => {
    const clearAllDataPersist = (storageObj = window.localStorage) => {
      const keysToRemove = [...Object.keys(storageObj).filter((key) => String(key).startsWith("employment-forms."))];
      keysToRemove.forEach((key) => {
        storageObj.removeItem(key);
      });
    };
    clearAllDataPersist(STORAGE);
  }, [STORAGE]);

  return (
    <>
      <Alert type="danger">{messagesDisplay}</Alert>
      <ButtonRow>
        <Button onClick={onClickButton}>{text}</Button>
      </ButtonRow>
    </>
  );
};

export default ErrorPage;
