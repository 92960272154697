import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalInstance, redirectRequest } from "../auth/msalConfig";
import ErrorPage from "./common/ErrorPage";
import PageLayout from "./common/PageLayout";
import { FormRouterErrorBoundary, NestedRouterErrorBoundary } from "./common/error-boundary";
import CardWithLoadingStatus from "./common/CardWithLoadingStatus";
import { StatusProvider } from "../entities/StatusContext";
import {
  GetAllEmployeesLoader,
  GetDepartureFormByIdLoader,
  GetOnboardingFormByIdLoader,
  GetOnboardingFormByOpportunityIdIdLoader,
  GetAllEnabledEmployeesLoader,
  GetRoleUpdateEmployeesLoader,
  GetRoleUpdateFormByIdLoader,
  GetTalentReviewActiveEmployeesLoader,
  GetProofOfEmploymentEmployeesLoader,
  GetProofOfEmploymentByIdLoader,
  GetEoYEmployeesLoader,
  GetRoleUpdateFormByOpportunityIdIdLoader,
  GetThirdPartyLoader,
} from "./common/loaders";

function Router({ pca = msalInstance }) {
  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={redirectRequest}
        loadingComponent={CardWithLoadingStatus}
      >
        <Pages />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

const WrapOutletWithContext = () => {
  return (
    <StatusProvider disabled={true}>
      <Outlet />
    </StatusProvider>
  );
};

function Pages() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <PageLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          lazy: () => import("./home/HomePage"),
        },
        {
          path: "logout",
          lazy: () => import("../components/common/Logout"),
        },
        {
          path: "success",
          lazy: () => import("../components/common/Success"),
        },
        {
          path: "error",
          element: <ErrorPage />,
        },
        {
          path: "form",
          errorElement: <FormRouterErrorBoundary />,
          children: [
            {
              path: ":formType/access-denied",
              lazy: () => import("./common/AccessDenied"),
            },
            {
              path: "departure",
              errorElement: <NestedRouterErrorBoundary />,
              loader: GetAllEmployeesLoader,
              id: "departure",
              children: [
                {
                  path: "",
                  lazy: () => import("../components/departure-form/creation/DepartureFormCreate"),
                },
                {
                  path: "list",
                  lazy: () => import("../components/departure-form/dashboard/DepartureDashboard"),
                },
                {
                  path: ":id/edit",
                  children: [
                    {
                      index: true,
                      loader: GetDepartureFormByIdLoader,
                      lazy: () => import("../components/departure-form/edit/DepartureFormEdit"),
                    },
                  ],
                },
                {
                  path: ":id/preview",
                  element: <WrapOutletWithContext />,
                  children: [
                    {
                      index: true,
                      loader: GetDepartureFormByIdLoader,
                      lazy: () => import("../components/departure-form/edit/DepartureFormEdit"),
                    },
                  ],
                },
              ],
            },
            {
              path: "onboarding",
              errorElement: <NestedRouterErrorBoundary />,
              loader: GetAllEnabledEmployeesLoader,
              id: "onboarding",
              children: [
                {
                  path: "",
                  loader: ({ request }) => GetOnboardingFormByOpportunityIdIdLoader(request.url),
                  lazy: () => import("../components/onboarding/create/OnboardingCreate"),
                },
                {
                  path: "list",
                  lazy: () => import("../components/onboarding/dashboard/OnboardingDashboard"),
                },
                {
                  path: ":id/edit",
                  children: [
                    {
                      index: true,
                      loader: GetOnboardingFormByIdLoader,
                      lazy: () => import("../components/onboarding/edit/OnboardingFormEdit"),
                    },
                  ],
                },
                {
                  path: ":id/preview",
                  element: <WrapOutletWithContext />,
                  children: [
                    {
                      index: true,
                      loader: GetOnboardingFormByIdLoader,
                      lazy: () => import("../components/onboarding/edit/OnboardingFormEdit"),
                    },
                  ],
                },
              ],
            },
            {
              path: "role-update",
              errorElement: <NestedRouterErrorBoundary />,
              loader: GetRoleUpdateEmployeesLoader,
              id: "role-update",
              children: [
                {
                  path: "",
                  loader: ({ request }) => GetRoleUpdateFormByOpportunityIdIdLoader(request.url),
                  lazy: () => import("../components/role-update/create/RoleUpdateCreate.jsx"),
                },
                {
                  path: "list",
                  lazy: () => import("../components/role-update/dashboard/RoleUpdateDashboard"),
                },
                {
                  path: ":id/edit",
                  children: [
                    {
                      index: true,
                      loader: GetRoleUpdateFormByIdLoader,
                      lazy: () => import("../components/role-update/edit/RoleUpdateEdit"),
                    },
                  ],
                },
                {
                  path: ":id/preview",
                  element: <WrapOutletWithContext />,
                  children: [
                    {
                      index: true,
                      loader: GetRoleUpdateFormByIdLoader,
                      lazy: () => import("../components/role-update/edit/RoleUpdatePreview"),
                    },
                  ],
                },
              ],
            },
            {
              path: "eoy-rating",
              errorElement: <NestedRouterErrorBoundary />,
              loader: GetEoYEmployeesLoader,
              id: "eoy-rating",
              children: [
                {
                  path: "",
                  lazy: () => import("../components/eoy-rating-form/creation/EoyRatingFormCreate"),
                },
              ],
            },
            {
              path: "parental-leave",
              errorElement: <NestedRouterErrorBoundary />,
              id: "parental-leave",
              children: [
                {
                  path: "",
                  lazy: () => import("../components/parental-leave-form/creation/ParentalLeaveForm"),
                },
              ],
            },
            {
              path: "proof-of-employment",
              errorElement: <NestedRouterErrorBoundary />,
              loader: GetProofOfEmploymentEmployeesLoader,
              id: "proof-of-employment",
              children: [
                {
                  path: "",
                  lazy: () => import("../components/proof-of-employment/create/ProofOfEmploymentFormCreate"),
                },
                {
                  path: ":id/edit",
                  children: [
                    {
                      index: true,
                      loader: GetProofOfEmploymentByIdLoader,
                      lazy: () => import("../components/proof-of-employment/edit/ProofOfEmploymentFormEdit"),
                    },
                  ],
                },
                {
                  path: ":id/preview",
                  element: <WrapOutletWithContext />,
                  children: [
                    {
                      index: true,
                      loader: GetProofOfEmploymentByIdLoader,
                      lazy: () => import("../components/proof-of-employment/edit/ProofOfEmploymentFormEdit"),
                    },
                  ],
                },
              ],
            },
            {
              path: "talent-review",
              errorElement: <NestedRouterErrorBoundary />,
              loader: GetTalentReviewActiveEmployeesLoader,
              id: "talent-review",
              children: [
                {
                  path: "",
                  lazy: () => import("../components/talent-review-form/creation/TalentReviewFormCreate"),
                },
              ],
            },
            {
              path: "third-party-contractor",
              errorElement: <NestedRouterErrorBoundary />,
              loader: GetThirdPartyLoader,
              id: "third-party-contractor",
              children: [
                {
                  path: "",
                  lazy: () => import("../components/third-party-contractor/creation/ThirdPartyContractorFormCreate"),
                },
                {
                  path: "list",
                  lazy: () => import("../components/third-party-contractor/dashboard/ThirdPartyContractorDashboard"),
                },
              ],
            },
          ],
        },
        {
          path: "reports",
          errorElement: <FormRouterErrorBoundary />,
          children: [
            {
              path: ":reportType",
              lazy: () => import("../components/reports/contractor-expiry/dashboard/ContractorExpiryDashboard"),
            },
          ],
        },
        {
          path: "*",
          lazy: () => import("./home/HomePage"),
        },
      ],
    },
  ]);

  if (import.meta.hot) {
    import.meta.hot.dispose(() => routes.dispose());
  }

  return <RouterProvider router={routes} future={{ v7_startTransition: true }} />;
}

export default Router;
