import { Navigation, MYOBLogo, CaretIcon, SignOutIcon, UserProfileIcon } from "@myob/myob-widgets";
import { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import UserContext from "../../auth/UserContext";
import { logoHomeStyle, customHamburgerStyle } from "./Header.css.ts";

const formTypes = [
  {
    id: "HelpMeFormsDeparture",
    name: "departure",
    enabled: {
      create: true,
      list: true,
    },
    directLink: false,
    title: "Departure Form",
  },
  {
    id: "HelpMeFormsNameChange",
    name: "nameChange",
    enabled: {
      create: true,
      list: false,
    },
    directLink: true,
    url: "https://helpme.myob.com/hc/en-us/requests/new?ticket_form_id=12237031585049&tf_12236885984921=people_form_name_change",
    title: "Name Change Form",
  },
  {
    id: "HelpMeFormsTransfer",
    name: "role-update",
    enabled: {
      create: true,
      list: true,
    },
    directLink: false,
    title: "Role Update/Transfer Form",
  },
  {
    id: "BulkReportingLineChange",
    name: "bulk-reporting-line-change",
    enabled: {
      create: true,
      list: false,
    },
    directLink: true,
    url: "https://helpme.myob.com/hc/en-us/requests/new?ticket_form_id=11439419669401&tf_11438992067993=pl_employment_bulk_transfer",
    title: "Bulk Reporting Line Change",
  },
  {
    id: "3rdPartyContractor",
    name: "third-party-contractor",
    enabled: {
      create: true,
      list: true,
    },
    directLink: false,
    title: "3rd Party Contractor Form",
  },
  {
    id: "HelpMeFormsNewStarter",
    name: "newStarter",
    enabled: {
      create: false,
      list: false,
    },
    directLink: true,
    title: "New Starter Form",
  },
  {
    id: "EmploymentFormsOnboarding",
    name: "onboarding",
    enabled: {
      create: false,
      list: true,
    },
    directLink: true,
    title: "Onboarding Form",
  },
  {
    id: "EmployeeFormsParentalLeave",
    name: "parental-leave",
    enabled: {
      create: true,
      list: false,
    },
    directLink: false,
    title: "Parental Leave Form",
  },
  {
    id: "EmployeeFormsProofOfEmployment",
    name: "proof-of-employment",
    enabled: {
      create: true,
      list: false,
    },
    directLink: false,
    title: "Proof of Employment Form",
  },
  {
    id: "EmployeeFormsEoyInitialRating",
    name: "eoy-rating",
    enabled: {
      create: true,
      list: false,
      peopleLeaderOnly: true,
    },
    directLink: false,
    title: "End of Year Indicative Rating for Pre-Calibration process",
  },
  {
    id: "PurchaseLeave",
    name: "purchase-leave",
    enabled: {
      create: false,
      list: false,
    },
    directLink: true,
    url: "https://helpme.myob.com/hc/en-us/requests/new?ticket_form_id=12237031585049&tf_12236885984921=people_leave_purchasing",
    title: "Purchase Leave",
  },
];

const ELA = "EMPLOYEE LIFECYCLE & AUTOMATIO";
const TEAM_MEMBER = "TEAM MEMBER";

export default function Header() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const user = useContext(UserContext);

  const brand = (
    <Navigation.Brand width="73px">
      <Link to="/" className={logoHomeStyle}>
        <MYOBLogo />
      </Link>
    </Navigation.Brand>
  );

  const isPeopleLeader = () => {
    if (user?.teamMemberLevel?.toUpperCase() !== TEAM_MEMBER) {
      return true;
    }

    return false;
  };

  const checkVisibility = ({ enabled }) => {
    if (user?.department?.toUpperCase() === ELA) {
      return true;
    }

    // menu option is visible if enabled.peopleLeaderOnly is undefined
    if (enabled.peopleLeaderOnly === undefined) {
      return true;
    }

    // menu option is visible if enabled.peopleLeaderOnly is true and user is a people leader
    if (enabled.peopleLeaderOnly && isPeopleLeader()) {
      return true;
    }

    return false;
  };

  const primary = [
    <Navigation.Menu
      key="menu-new-form"
      label="New Form"
      icon={<CaretIcon />}
      items={formTypes
        .filter((formType) => formType.name && formType.enabled.create && checkVisibility(formType))
        .map((formType) => (
          <Link key={`menu-new-${formType.id}`} to={formType.url || `/form/${formType.name}`} className={logoHomeStyle}>
            <Navigation.MenuLink label={formType.title} />
          </Link>
        ))}
    />,
    <Navigation.Menu
      key="menu-manage"
      label="Employee Form Dashboard"
      icon={<CaretIcon />}
      items={formTypes
        .filter((formType) => formType.name && formType.enabled.list)
        .map((formType) => (
          <Link key={`menu-list-${formType.id}`} to={`/form/${formType.name}/list`} className={logoHomeStyle}>
            <Navigation.MenuLink label={formType.title} />
          </Link>
        ))}
    />,
    <Navigation.Menu
      key="menu-report"
      label="Reports"
      icon={<CaretIcon />}
      items={[
        <Link key={`menu-list-contractor-expiry`} to={`/reports/contractor-expiry`} className={logoHomeStyle}>
          <Navigation.MenuLink label={"3rd Party Contractor Expiry"} />
        </Link>,
      ]}
    />,
  ];

  const secondary = [
    <Navigation.Menu
      key="user-menu"
      label={`Hello ${user ? `${user.firstName} ${user.surname}` : activeAccount?.name || ""}`}
      icon={<UserProfileIcon />}
      items={[
        <Link key={`logout`} to={`/logout`} className={logoHomeStyle}>
          <Navigation.MenuLink label={"Logout"} icon={<SignOutIcon />} iconRight />
        </Link>,
      ]}
    />,
  ];

  return <Navigation className={customHamburgerStyle} brand={brand} primary={primary} settings={secondary} />;
}
