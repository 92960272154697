import { StatusCodes } from "http-status-codes";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { GenericError } from ".";

function NestedRouterErrorBoundary() {
  const error = useRouteError();
  if (isRouteErrorResponse(error) && error.status === StatusCodes.NOT_FOUND) {
    const notFoundMessages = [`Sorry, we cannot find this form`];

    return <GenericError errorMessages={notFoundMessages} />;
  }

  if (isRouteErrorResponse(error) && error.status === StatusCodes.BAD_REQUEST && error?.data === "Incorrect Format") {
    const incorrectFormatMessages = ["Incorrect Format"];

    return <GenericError errorMessages={incorrectFormatMessages} />;
  }

  throw error;
}

export default NestedRouterErrorBoundary;
export const Component = NestedRouterErrorBoundary;
