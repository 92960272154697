import {
  addDays,
  differenceInMilliseconds,
  differenceInMonths,
  endOfDay,
  format,
  isValid,
  parse,
  startOfToday,
  startOfTomorrow,
} from "date-fns";
import {
  anyPass,
  complement,
  compose,
  curry,
  defaultTo,
  filter,
  includes,
  is,
  isEmpty,
  isNil,
  keys,
  map,
  omit,
  pipe,
  replace,
  startsWith,
  toPairs,
  trim,
  when,
  match,
} from "ramda";
import { StatusCodes } from "http-status-codes";
import { checkPermission } from "../apis/kilnBackendApis";

export const compareString = (a, b) => {
  const nameA = a?.toUpperCase(); // ignore upper and lowercase
  const nameB = b?.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

export const applySort = (data, sortFn, descending) => {
  const result = data.slice();
  result.sort(sortFn);
  return descending ? result.reverse() : result;
};

export const arrayExtractor = (array) => {
  return isNil(array) ? undefined : array[0];
};

export const compareDate = (dateLeft, dateRight, formatReference) => {
  return differenceInMilliseconds(
    parse(dateLeft, formatReference, new Date()),
    parse(dateRight, formatReference, new Date()),
  );
};

export const dateFormat = {
  ddMMyyyyWithSlash: "dd/MM/yyyy",
  yyyyMMdd: "yyyyMMdd",
  yyyyMMddWithDash: "yyyy-MM-dd",
  doMMMyyyyWithSpace: "do MMM yyyy",
};

export const calculateTotalPages = (total, size) => Math.ceil(total / size);

export const isNotNilEmpty = complement(anyPass([isNil, isEmpty]));

export const isNilOrEmpty = anyPass([isNil, isEmpty]);

export const todayDateFormatted = () => {
  return format(startOfToday(), dateFormat.yyyyMMddWithDash);
};

export const tomorrowDateFormatted = () => {
  return format(startOfTomorrow(), dateFormat.yyyyMMddWithDash);
};

export const guessAndFormatATimeString = (timeString, targetFormat = "HH:mm", defaultFallback = "09:00") => {
  // assume timeString in a format like 9:00am
  if (isNilOrEmpty(timeString)) {
    return defaultFallback;
  }
  // guarantee there are no multiple whitespace, all upper case and no dot
  const wellFormedTimeString = String(timeString).replace(/  +/g, " ").toUpperCase().replaceAll(".", "");
  const amPmRegEx = /([ap])\.m\.|([ap])m|([AP])\.M\.|([AP])M/gi;
  const amPmIndex = String(wellFormedTimeString).search(amPmRegEx);
  if (amPmIndex === -1) {
    // no matching for regex
    // potentially a 24-hour string
    if (isValid(parse(wellFormedTimeString, targetFormat, new Date()))) {
      return wellFormedTimeString;
    }
    return defaultFallback;
  }
  let potential12HourTimeString = wellFormedTimeString;
  // there is matching regex
  if (wellFormedTimeString[amPmIndex - 1] !== " ") {
    // no space between numeric time and am/pm
    // we need to add the whitespace
    potential12HourTimeString =
      wellFormedTimeString.substring(0, amPmIndex) + " " + wellFormedTimeString.substring(amPmIndex);
  }
  if (isValid(parse(potential12HourTimeString, "hh:mm aa", new Date()))) {
    const parsedDate = parse(potential12HourTimeString, "hh:mm aa", new Date());
    return format(parsedDate, targetFormat);
  }

  return defaultFallback;
};

export const isIncluded = (formArray, targetString) => {
  if (isNotNilEmpty(formArray)) {
    return formArray.includes(targetString);
  }
  return false;
};

export const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
};

export const compareMaxStartDateWithPickedFinishDate = (pickedFinishDate) => {
  return isNil(pickedFinishDate) ? null : pickedFinishDate;
};

export const compareMinEndDateWithPickedStartDate = (pickedStartDate) => {
  return isNil(pickedStartDate) ? format(startOfToday(), "yyyy-MM-dd") : pickedStartDate;
};

export function sanitiseFormData(data) {
  // Some of the drop-downs have object data instead of strings so we need
  // to extract them here before we send it to the backend.
  const keysToRemove = extractUnderscoredKeysFromObj(data);
  const saneData = { ...omit(keysToRemove)(data) };
  // string replace '<>' with space and trim
  const replaceSpecialChars = pipe(replaceSpecialCharsWithWhiteSpace, stripWhitespaceFromBothEnds);
  // position details
  saneData.function = data?.function?.function || undefined;
  saneData.group = data?.group?.group || undefined;
  saneData.department = data?.department?.department || undefined;
  saneData.team = data?.team?.team || undefined;
  saneData.vertical = data?.vertical?.vertical || undefined;
  saneData.segment = data?.segment?.segment || undefined;
  saneData.costCentre = data?.costCentre?.costCentre || undefined;
  saneData.projectCostCentre = data?.projectCostCentre?.costCentre || undefined;
  saneData.positionTitle = data?.positionTitle || undefined;
  // IT details
  saneData.archieAnalyticIdenticalAccess = data?.archieAnalyticIdenticalAccess?.fullName || undefined;
  saneData.archieIdenticalAccess = data?.archieIdenticalAccess?.fullName || undefined;
  saneData.archieReportingManager = data?.archieReportingManager?.fullName || undefined;

  // effectiveDate fallback
  saneData.effectiveDate =
    saneData?.effectiveDate || saneData?.startDate || format(startOfToday(), dateFormat.yyyyMMddWithDash);
  Object.keys(saneData).forEach((param) => {
    if (typeof saneData?.[param] === "string") {
      saneData[param] = replaceSpecialChars(saneData[param]);
    }
  });
  if (isNotNilEmpty(data?.baseSalary)) {
    saneData.baseSalary = data.baseSalary.toString();
  }
  if (
    isNotNilEmpty(data?.primaryOffice) &&
    String(data?.primaryOffice).toUpperCase().startsWith("AU") &&
    isNotNilEmpty(data?.superannuation) &&
    isNotNilEmpty(data?.totalSalary)
  ) {
    saneData.superannuation = data.superannuation.toString();
    saneData.totalSalary = data.totalSalary.toString();
  }
  return saneData;
}

/**
 * Returns T/F based on whether the date is within X-amount of days from today.
 * @param {string} dateToCheck the date this func is checking in yyyy-MM-dd
 * @param {number} duration how many days from now on
 */
export const isWithinXAmountOfDaysInTheFuture = (dateToCheck, duration) => {
  const parsedDateToCheck = parse(dateToCheck, dateFormat.yyyyMMddWithDash, new Date());
  const xAmountOfDaysFromNow = addDays(Date.now(), duration);
  return startOfToday() <= parsedDateToCheck && parsedDateToCheck <= xAmountOfDaysFromNow;
};

export const calculateProbationPeriod = (startDate, endDate) => {
  const parsedStart = new Date(parse(startDate, dateFormat.yyyyMMddWithDash, new Date()));
  const parsedEnd = new Date(parse(endDate, dateFormat.yyyyMMddWithDash, new Date()));
  const diffInMonths = differenceInMonths(parsedEnd, parsedStart);
  // Contracts of longer than 12 months have probation period of six months
  if (diffInMonths >= 12) {
    return "6";
  } else {
    const result = Math.round(diffInMonths * 0.3);
    return result && !Number.isNaN(result) ? result.toString() : "0";
  }
};

export const isLocationInPreviewMode = (location) => {
  const currentPathname = location?.pathname.toLowerCase() || "";
  return currentPathname.includes("preview");
};

export const extractUnderscoredKeysFromObj = (obj) => {
  const keyList = keys(obj);
  const startsWithUnderscore = startsWith("_");
  return filter(startsWithUnderscore, keyList);
};

/**
 * Strings in JS are represented fundamentally as sequences of UTF-16 code units
 * See here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String#utf-16_characters_unicode_code_points_and_grapheme_clusters
 * NodeJS in this regard buries the conversion logic in C++ code,
 * https://github.com/nodejs/node/blob/5c65565108c626884c5c722bb512c7c1e5c1c809/src/string_bytes.cc#L317-L375
 * which eventually calls out to the V8 binary to handle it.
 * This method replaces each instance of certain characters by one, two, three,
 * or four escape sequences representing the UTF-8 encoding of the character
 * @param {string} str
 * @returns {string} RFC5987-compliant string
 */
const encodeRFC5987ValueChars = (str) =>
  encodeURIComponent(str)
    // The following creates the sequences %27 %28 %29 %2A (Note that
    // the valid encoding of "*" is %2A, which necessitates calling
    // toUpperCase() to properly encode). Although RFC3986 reserves "!",
    // RFC5987 does not, so we do not need to escape it.
    .replace(/['()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`)
    // The following are not required for percent-encoding per RFC5987,
    // so we can allow for a little better readability over the wire: |`^
    .replace(/%(7C|60|5E)/g, (str, hex) => String.fromCharCode(parseInt(hex, 16)));

/** Replaces long dash characters in a filename */
export const fixFileName = (filename) => {
  return encodeRFC5987ValueChars(filename);
};

export const stripWhitespaceFromBothEnds = when(is(String), trim);
export const replaceSpecialCharsWithWhiteSpace = when(is(String), replace(/[<>]/g, " "));

/**
 * Given a full name it returns its legalFirstName,
 * preferredFirstName, and surname in a object.
 * @param {string} fullName Full name to check.
 * @returns {object} Return object { legalFirstName, preferredFirstName, surname },
 */
export const extractFullNameValues = (fullName) => {
  let legalFirstName, preferredFirstName, surname;
  legalFirstName = preferredFirstName = surname = "";

  if (isNotNilEmpty(fullName)) {
    const fullNameList = fullName.trim().split(" ") || [];

    if (fullNameList.length === 1) {
      // Either first name or surname.
      legalFirstName = preferredFirstName = fullNameList[0];
    } else if (fullNameList.length === 2) {
      // first name + surname.
      legalFirstName = preferredFirstName = fullNameList[0];
      surname = fullNameList[1];
    } else if (fullNameList.length === 3) {
      // first name + preferred first name + surname.
      legalFirstName = fullNameList[0];
      preferredFirstName = fullNameList[1];
      surname = fullNameList[2];
    }
  }
  return {
    legalFirstName,
    preferredFirstName,
    surname,
  };
};

const toFixedDecimal = (inputNumber, decimals = 2) => {
  const result = inputNumber.toFixed(decimals);
  return result <= inputNumber ? result : (result - Math.pow(0.1, decimals)).toFixed(decimals);
};

/** Australian superannuation rate value. */
const AUSTRALIAN_SUPER_RATE = 0.115;

// Return superannuation and total salary values as numbers
// given a base salary with only two decimals,
// and without rounding if it's required.
export const calculateSuperannuationAndTotalSalary = (baseSalaryValue) => {
  let superannuationValue, totalSalaryValue;
  superannuationValue = totalSalaryValue = 0;

  if (isNotNilEmpty(baseSalaryValue)) {
    superannuationValue = toFixedDecimal(baseSalaryValue * AUSTRALIAN_SUPER_RATE);
    let decimalPart;
    if (superannuationValue.indexOf(".") > 0) {
      // Remove trailing 0's.
      decimalPart = superannuationValue.split(".")[1].replace(/0+$/, "");
      const integerPart = Math.trunc(superannuationValue);
      if (isNilOrEmpty(decimalPart)) {
        superannuationValue = integerPart;
      } else {
        superannuationValue = parseFloat(`${integerPart}.${decimalPart}`);
      }
    }
    totalSalaryValue = parseInt(baseSalaryValue, 10) + parseInt(superannuationValue, 10);
    if (decimalPart) {
      totalSalaryValue = parseFloat(`${totalSalaryValue}.${decimalPart}`);
    }
  }
  return {
    superannuationValue,
    totalSalaryValue,
  };
};

//verifyRole takes history hook, permission check form type and the form name for the error message
export const verifyRole = async (history, form, formName) => {
  const permissionResp = await checkPermission(form);
  if (permissionResp.status !== StatusCodes.OK) {
    history("/error", {
      state: {
        messages: [
          `Sorry, ${formName} access is restricted to MYOB People Leaders.`,
          "If you do believe you should have access, please contact your manager or People Consultants, or raise a HelpMe ticket.",
        ],
      },
    });
    return false;
  } else {
    return true;
  }
};

export const clearFields = (formPersist) => {
  formPersist.clear();
  window.location.reload();
};

export const cancelForm = (formPersist, history) => {
  formPersist.clear();
  history("/");
};

export const compareLocalStorageArrayLength = (employees, formPersist, storage, form) => {
  let storageArray = [];
  const _storage = localStorage.getItem(storage);
  if (_storage) {
    storageArray = JSON.parse(_storage)[form];
  }
  if (employees?.length > 0 && employees?.length !== storageArray?.length) {
    formPersist.clear();
  }
};

/**
 *
 * @param {array} currentOfficeLocationList Current location list that are displayed in the dropdowns
 * @param {string} selectedLocation The selected office location
 */
export const getLocationList = (currentOfficeLocationList, selectedLocation) => {
  const _alteredOfficeList = currentOfficeLocationList.some((e) => e.label === selectedLocation)
    ? currentOfficeLocationList
    : [...currentOfficeLocationList, { name: selectedLocation, label: selectedLocation }];
  return _alteredOfficeList.sort((a, b) => a.label.localeCompare(b.label));
};

export const safeIncludes = curry((query, target) => includes(query, defaultTo([])(target)));

export const formTypeToApiEndpointMapping = {
  "departure": "departure",
  "onboarding": "onboarding",
  "role-update": "role-update",
  "talent-review": "talent-review",
  "third-party-contractor": "third-party-contractor",
  "eoy-rating": "eoy-rating",
};

export const formTypeToFormNameMapping = {
  "departure": "Departure",
  "onboarding": "Onboarding",
  "role-update": "Role-Update/Transfer Form",
  "talent-review": "Talent Review",
  "third-party-contractor": "Third Party Contractor",
  "eoy-rating": "EOY Rating",
};

export const stringifySearchParams = (searchParamObj = {}) => {
  const sanitize = compose(toPairs, map(defaultTo("")));
  const sortedSearchParams = new URLSearchParams([...sanitize(searchParamObj)]);
  sortedSearchParams.sort();

  return sortedSearchParams.toString();
};

export const clearFormAndScrollToTop = async (form) => {
  await form.reset();
  // Setting the scroll position to the top -1 as 0 is not working on Safari & Firefox
  window.scrollTo({ top: -1, left: 0, behavior: "smooth" });
};
export const transformFinishDateToNumber = (finishDateString) => {
  const date = parse(finishDateString, "yyyy-MM-dd", new Date());
  if (isValid(date)) {
    return endOfDay(date).getTime();
  }
};
export const transformStartDateToNumber = (startDateString) => {
  // parse will convert string to Date with env TimeZone.
  const date = parse(startDateString, "yyyy-MM-dd", new Date());
  if (isValid(date)) {
    return date.getTime();
  }
};

//this function is used to set the value of the dropdowns
//options has to be an array of objects with a label key - ensure options is passed in correctly before calling this function
export const handleSetValue = (name, value, options = [], setValue) => {
  const _options = options?.map((option) => option.label);
  if (isNotNilEmpty(value) && _options?.includes(value)) {
    setValue(name, value);
  }
};

export const isLocationInEditOrPreviewMode = (location) => {
  const currentPathname = location?.pathname.toLowerCase() || "";
  return isNotNilEmpty(match(/edit|preview/g, currentPathname));
};
