import { StatusCodes } from "http-status-codes";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { GenericError } from ".";
import { HELP_ME_TICKET_URL } from "../helpMeTicketUrl";

function FormRouterErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === StatusCodes.FORBIDDEN) {
    const formType = error?.data?.formType || "Employment";
    const messages = [
      `Sorry, ${formType} Forms access is restricted to MYOB People Leaders.`,
      "If you do believe you should have access, please contact your manager or People Consultants.",
    ];
    return <GenericError errorMessages={messages} />;
  }

  if (isRouteErrorResponse(error) && error.status === StatusCodes.IM_A_TEAPOT) {
    const formType = error?.data?.formType || "";
    const preConditionFailureByFormType = {
      "Talent Review": [
        "This form is only available / applicable to MYOB People Leaders to complete the Talent Review for their team.",
        "If you have direct reports and are still unable to access this form, please contact us.",
      ],
      "End of Year - Form Closed": ["This form is currently closed."],
      "End of Year - No Direct Reports": [
        "This form is only available/applicable to MYOB People Leaders to complete their team's End of Year (EOY) Indicative Rating.",
        `If you have direct reports and are still unable to access this form, please contact us <a href="${HELP_ME_TICKET_URL}" target="_blank" rel="noopener noreferrer">here</a>.`,
      ],
    };
    return (
      <GenericError
        errorMessages={preConditionFailureByFormType[formType] ?? null}
        hideDefaultHelpMeTicketText={true}
      />
    );
  }

  throw error;
}

export default FormRouterErrorBoundary;
export const Component = FormRouterErrorBoundary;
